import * as React from 'react';
import {useState, useEffect} from 'react';
import { Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from  'react-loader-spinner';
import Auth from '../components/Auth';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export interface Props {
	page : string;
}


const Header: React.FC<Props> = ({page}) => {

  	const [loading, setLoading] = useState<any>(false);
  	const [username, setUsername] = useState<String>('');
	const [isLogged, setIsLogged] = useState<boolean>(false);

	useEffect(() => {
		Auth.isAuthenticated((val:string) => {
			if(!val)
				window.location.replace("/login");
			else{
				const user = JSON.parse(val);
				setUsername(user.username);
				setIsLogged(true)
			}
		})
	}, []);

  const logout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
	console.log("Logout...")
    localStorage.removeItem('user');
    window.location.reload();
  }

  const createJSON = (e:React.FormEvent) => {
        
	const url = process.env.REACT_APP_BASE_URL + '/api/save.php';
	const payload = {
		save : 1
	}
	fetch(url, {
		method : "POST",
		body : JSON.stringify(payload)
	})
	.then(response => response.json())
	.then(data => {
		toast.success(data.msg, {
			position: toast.POSITION.TOP_CENTER,
			theme: "colored"
		});
	})
	.catch(err => {
		console.log(err);
	});
}

const publishJSON = (e:React.FormEvent) => {
	e.preventDefault();
	
	confirmAlert({
		title: 'Confirm to publish',
		message: 'Are you sure you want to publish your changes? Make sure to save your changes before',
		buttons: [
		  {
			label: 'Yes',
			onClick: () => {
				setLoading(true);
				const url = process.env.REACT_APP_BASE_URL + '/api/publish.php';
				const payload = {
					publish : 1
				}
				fetch(url, {
					method : "POST",
					body : JSON.stringify(payload)
				})
				.then(response => response.json())
				.then(data => {
					if(data.action === 'failed'){
						toast.error(data.msg, {
							position: toast.POSITION.TOP_CENTER,
							theme: "colored"
						});
						setLoading(false);
					}else{
						toast.success(data.msg, {
							position: toast.POSITION.TOP_CENTER,
							theme: "colored"
						});
						setLoading(false);
					}                      
				})
				.catch(data => {
					toast.error(data.msg, {
						position: toast.POSITION.TOP_RIGHT,
						theme: "colored"
					});
					setLoading(false);
				});
			}
		  },
		  {
			label: 'No',
			onClick: () => {

			}
		  }
		],
		closeOnEscape: true,
		closeOnClickOutside: true,
		keyCodeForClose: [8, 32],
		willUnmount: () => void{},
		// afterClose: () => {},
		onClickOutside: () => {},
		onKeypressEscape: () => {}
	  });
}

  return (
	
    <nav className="navbar navbar-expand-lg navbar-dark " style={{ background:'#ff1195', height:'80px'}}>
		<ToastContainer /> 
		<div className="container-fluid">
			<Link className="navbar-brand" to="/"><h5>Arts Happen</h5></Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse justify-content-between" id="navbarNavDropdown">
				<ul className="navbar-nav">
					
					<li className="nav-item">
						{
							page === 'listing' ? 
							<Link className="nav-link active" to="/">Listing</Link>
						:
							<Link className="nav-link" to="/">Listing</Link>
						}
					</li>
				
					<li className="nav-item">
						{
							page === 'add' ? 
							<Link className="nav-link active" to="/add">Add</Link>
						:
							<Link className="nav-link" to="/add">Add</Link>
						}
					</li>
					
				</ul>
				<ul className="navbar-nav ml-auto" style={{ float:'right', alignItems : 'center' }}>
					<li>
					<RotatingLines
						strokeColor="grey"
						strokeWidth="5"
						animationDuration="0.75"
						width="50"
						visible={loading}
					/>
					</li>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSON}>Validate</button> 
					</li>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-danger" onClick={publishJSON}>Publish</button>
					</li>
					{
					isLogged ?
					<li className="nav-item">
						<div className="collapse navbar-collapse" id="navbar-list-4">
							<ul className="navbar-nav">
								<li className="nav-item dropdown">
									<a className="nav-link dropdffffown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
										<img src="/user.png" width="40" height="40" className="rounded-circle"/>
										<span style={{fontSize:'12px',color:'#fff',margin:'0 5px'}} className="d-none d-sm-block ms-1" onClick={logout}>Logout</span>
									</a>
								</li>   
							</ul>
						</div>
					</li>
					: ''
					}
					{/* <li className="nav-item ml-auto">
						<div className="collapse navbar-collapse ml-auto" id="navbar-list-4">
						{
						isLogged ?
						<button onClick={logout} className="btn btn-light" name="logout">Logout</button>
						: ''
						}
							<ul className="navbar-nav">
								<li className="nav-item dropdown">
									<a className="nav-link" href="/#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
										<img alt="user" src="/user.png" width="25" height="25" className="rounded-circle"/>
										<span style={{fontSize:'12px',color:'#fff',margin:'0 5px'}} className="d-none d-sm-block ms-1">{username}</span>
									</a>
								</li>   
							</ul> 
						</div>
					</li> */}
				</ul>
			</div>
		</div>
	</nav>
  );
}

export default Header;
