import React, { Component } from 'react';

class Auth extends Component {

    isAuthenticated = (callback) => {
        const name = localStorage.getItem('user');
        if (name)
            callback(name)
        else
            callback(false);
    }

     render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default new Auth();

 
