import * as React from 'react';
import {useState, useEffect} from 'react';
import Header from '../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import {Car} from '../Interfaces/Cars'
import {IntCat} from '../Interfaces/IntCat'
import { RotatingLines } from  'react-loader-spinner';
import {AiFillEdit} from 'react-icons/ai'
import {MdDelete} from 'react-icons/md'
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';


export interface Props {
}

const Listing:React.FC<Props> = () => {

    const [list, setList] = useState<Car[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<IntCat[]>([])
    const navigate = useNavigate();
    const bold = {
        fontWeight : '600'
    }
    const handleDelete = (e:React.FormEvent, uniqid : string, i:number) => {
        e.preventDefault();

        confirmAlert({
            title: 'Deletion confirmation',
            message: 'Are you sure you want to delete this car?',
            buttons: 
            [
                {
                    label: 'Yes',
                    onClick: () => {
                        const endpoint = process.env.REACT_APP_BASE_URL + '/api/delete.php';
                        fetch(endpoint, {
                            "method": "POST",
                            "body": JSON.stringify({id : uniqid})
                        })
                        .then(response => response.json())
                        .then(response => {     
                            if(response.action === "failed"){
                                toast.error(response.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    theme: "colored"
                                });
                            }else{
                                toast.success(response.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    theme: "colored"
                                });
                                list.splice(i, 1);
                                setList(list);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2500);                    
                            }
                        })
                        .catch(data => {
                            toast.error(data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                theme: "colored"
                            });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => void{},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
          });

     
    }
   
    const handleCategory = async (e:React.ChangeEvent, val:string) => {
        var table = $('#file_export').DataTable();
        table.search('"' + val + '"').draw();

        navigate('/category/' + val)

        // const apiurl = process.env.REACT_APP_BASE_URL + '/api/list.php';
        // const response = await fetch(apiurl, {
        //     method : "POST",
        //     body : JSON.stringify({category : val})
        // });
        // const data = await response.json();
        // //const newList = data.results.filter((item:Car) => (item.category === val));
        // try{
        //     var table = new $.fn.dataTable.Api('#file_export');
        //     table.clear();
        //     setList(data.results);
        //     if(!$.fn.dataTable.isDataTable("#file_export"))
        //         $('#file_export').DataTable({
                   
        //         }).ajax.reload();
             
        // }catch(e){
        //     console.error(e)
        // }
    }
    
    useEffect(() => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/getCategories.php';
        fetch(apiurl, {
            method : "POST",
            body : JSON.stringify({})
        })
        .then(response => response.json())
        .then(data => setCategories(data.results))
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        const url = process.env.REACT_APP_BASE_URL + '/api/list.php';
        setLoading(true)
        fetch(url, {
            method : "POST",
            body : JSON.stringify({})
        })
        .then(response => response.json())
        .then(data => {
            setList([...data.results])
        })
        .then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    if($('#file_export').length > 0 ){
                        
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
                            //    destroy : false,
                            //    paging : false,
                            //    deferRender: true,
                            //    ordering : false,
                            //    searching : true,
                            "columns": [
                                { "searchable": false },
                                { "searchable": true },
                                { "searchable": true },
                                { "searchable": true },
                                { "searchable": true },
                                { "searchable": true },
                                { "searchable": false },
                                { "searchable": false },
                                { "searchable": false },
                                { "searchable": false },

                              ] } );
                            clearInterval(caller)                            
                        }
                    }
                },
                100
            )
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
        
    }, []);

    return (
    <div>
       <>
        <Header page="listing" />
        <br/>
        <div className="form-group">
            <div className="col">
                <label style={{fontWeight:'bold'}}>Category</label>
                <select className="form-control" style={{width:'100%'}} onChange={(e) => handleCategory(e, e.target.value)}>
                    <option value=''>All</option>
                    {
                    categories.map((item, i) => (
                        <option value={item.name} key={i}>{item.name + ' (' + item.nb + ')'}</option>
                    ))
                    }
                </select>
            </div>
        </div>
        
        <div className="table-responsive">  
                <ToastContainer />    
                <RotatingLines
                    strokeColor="rgb(13, 110, 253)"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loading}
                />
               
                {loading ? '' : 
                <table id="file_export" className="table table-striped table-bordered display small">
                    <thead>
                        <tr>
                           
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Category</th>
                            <th>Style</th>
                            <th>Provider</th>
                            <th>Published date</th>
                            <th>Last Updated</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        list.map((item, i) => (
                            <tr key={i} >
                               
                                <td>
                                    <div className="image">
                                        <img alt={item.title} src={item.image}/>
                                    </div> 
                                </td>
                                <td style={bold}>{item.title}</td>
                                <td>{(item.description.length > 100) ? item.description.substring(0, 50) + '...' : item.description}<span><a href={item.link} target="_blank" rel="noopener noreferrer">&nbsp;Read More</a></span></td>
                                <td>{item.category}</td>
                                <td>{item.style}</td>
                                <td>{item.provider.title}</td>
                                <td>{item.pubDate}</td>
                                <td>{item.createdAt}</td>
                                <td><Link to={"/edit/" + item.uniqid}><button type="button" className="btn btn-info"><AiFillEdit /></button></Link></td>
                                <td><button type="button" className="btn btn-danger"><span onClick={(e) => handleDelete(e, item.uniqid, i)}><MdDelete/></span></button></td>
                            </tr>              
                        ))    
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Category</th>
                            <th>Style</th>
                            <th>Provider</th>
                            <th>Published date</th>
                            <th>Last Updated</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </tfoot>
                </table>
                }
            </div>
        </>
    </div>
  );
}

export default Listing
