import React, {FC} from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Add from './Pages/Add';
import Edit from './Pages/Edit';
import Category from './Pages/Category';
import Listing from './Pages/Listing'
import Login from './Pages/Login'

interface Props{
}

const App:FC<Props> = () => {
  return (
	<Router>
		<Routes>
			<Route path = "/" element={<Listing/>} />
			<Route path = "/add" element={<Add/>} />
			<Route path = "/login" element={<Login/>} />
			<Route path = "/edit/:id" element={<Edit/>} />
			<Route path = "/category/:category" element={<Category/>} />
		</Routes> 
	</Router>

  );
}

export default App;
