import Header from "../components/Header";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {CarsStyles} from "../components/CarsStyles"
import {IntCat} from '../Interfaces/IntCat'

interface Props{
  
}

const Add:React.FC<Props> = () => {

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [style, setStyle] = useState<string>('');
    const [pubDate, setPubDate] = useState<Date>(new Date());
    const [link, setExternalUrl] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [showImage, setShowImage] = useState<boolean>(false);
    const [date, setDate] = useState<Date>(new Date());
    const [categories, setCategories] = useState<IntCat[]>([]);
   
    const bold = {
        fontWeight : '600'
    }
    const titleRef = useRef<HTMLTextAreaElement>(null);
    const dateRef = useRef(null);

    const getCategories = async () => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/getCategories.php';
        const response = await fetch(apiurl, {
            method : "POST",
            body : JSON.stringify({})
        });
        const data = await response.json();
        setCategories(data.results);
    }

    useEffect(() => {
        getCategories();
    }, []);
    

    const handleSave = (e:React.FormEvent) => {
        e.preventDefault();
        const data = {
            title : title,
            description : description,
            category : category,
            style : style,
            pubDate : pubDate,
            link : link,
            image : image
        }
      
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/add.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    };
    
    const handleTitle = (e:React.FormEvent, title:string) => {
        const target = e.target as HTMLTextAreaElement;
        setTitle(title)
        if(title.length < 1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleDescription = (e:React.FormEvent, description:string) => {
        const target = e.target as HTMLTextAreaElement;
        setDescription(description)
        if(description.length < 1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleCategory = (e:React.FormEvent, category:string) => {
        const target = e.target as HTMLInputElement;
        setCategory(category)
        if(category.length < 1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleStyle = (e:React.FormEvent, style:string) => {
        const target = e.target as HTMLInputElement;
        setStyle(style)
        if(style.length < 1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleExternalUrl = (e:React.FormEvent, link:string) => {
        const target = e.target as HTMLInputElement;
        setExternalUrl(link)
        if(link.length < 1 || link.search(/https?:\/\//i) === -1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        } 
    }

    const handleImage = (e:React.FormEvent, image:string) => {
        const target = e.target as HTMLInputElement;
        setImage(image)
        if(image.length < 1 || image.search(/https?:\/\//i) === -1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        } 
    }

    const handlePubDate = (dt:Date) => {
        console.log(dt)
        setDate(dt)
        setPubDate(dt)
    }

    const generateImage = () => {
      setShowImage(true)
    }

    return (
        <div>
            <ToastContainer />
            <Header page="edit"/>
            <div className="container small">
                <form style={{ marginTop:'30px' }}>
                    <div className="container-add">
                        {
                        showImage ?
                        <div className="edit-item" style={{ margin:'10px', alignSelf:'flex-start'}}>
                            <div className="card d-flex justify-content-center align-items-center" style={{ alignItems:'center', maxWidth:'400px', margin : '10px auto' }}>
                                <img className="card-img-top" src={image} alt={title} style={{maxWidth:'400px'}}/>
                                <div className="card-body">
                                    <h5 className="card-title">{title}</h5>
                                    <p className="card-text">{description}</p>
                                </div>
                            </div>
                        </div>
                        : ''
                        }
                        <div className="edit-item" style={{ width:'100%',margin:'10px' }}>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Title</label>
                                <div className="controls">
                                    <textarea ref={titleRef} rows={1} className="form-control small" value={title} onChange={(e) => handleTitle(e, e.target.value)}></textarea>
                                    <div className="invalid-feedback">The title field is required (Min 5 characters)</div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Description</label>
                                <div className="controls">
                                    <textarea rows={1} className="form-control small" value={description} onChange={(e) => handleDescription(e, e.target.value)}></textarea>
                                    <div className="invalid-feedback">The description field is required (Min 10 characters)</div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Category</label>
                                <select className="form-control" value={category} onChange={(e) => handleCategory(e, e.target.value)}>
                                    {
                                    categories.map((item, i) => (
                                        <option value={item.name} key={i}>{item.name}</option>
                                    ))
                                    }
                                </select>
                            </div>

                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Style</label>
                                <select className="form-control" style={{width:'100%'}} onChange={(e) => handleStyle(e, e.target.value)}>
                                    {
                                    CarsStyles.map((item, i) => (
                                        <option value={item} key={i}>{item}</option>
                                    ))
                                    }
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>External Url</label>
                                <input type="text" className="form-control" id="externalUrl" value={link} onChange={(e) => handleExternalUrl(e, e.target.value)}/>
                                <div className="invalid-feedback">The external Url is required</div>
                                <div className="valid-feedback"></div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Image Url</label>
                                <input type="text" className="form-control" id="externalUrl" value={image} onChange={(e) => handleImage(e, e.target.value)} onBlur={generateImage}/>
                                <div className="invalid-feedback">The Image Url is required</div>
                                <div className="valid-feedback"></div>
                            </div>
                            <div className="form-group mb-3">
                                <label style={bold}>Pub Date</label>
                                <DatePicker 
                                ref={dateRef}
                                    dateFormat="yyyy-MM-dd"
                                    selected={date} 
                                    onChange={(date:Date) => handlePubDate(date)}
                                />
                                {/* <input type="text" className="form-control" id="pubdate" value={pubDate} onChange={(e) => handlePubDate(e, e.target.value)}/> */}
                                <div className="invalid-feedback">The Pub date is required</div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
            
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Add